export const TemplateCategoryEnum = {
  ALIMTALK: 'ALIMTALK',
  FRIENDTALK: 'FRIENDTALK',
  SMS: 'SMS/LMS/MMS',
};

export const categoriesList = [
  {
    value: TemplateCategoryEnum.ALIMTALK,
    label: TemplateCategoryEnum.ALIMTALK,
  },
  {
    value: TemplateCategoryEnum.FRIENDTALK,
    label: TemplateCategoryEnum.FRIENDTALK,
  },
  {
    value: TemplateCategoryEnum.SMS,
    label: TemplateCategoryEnum.SMS,
  },
];
