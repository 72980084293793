import styled from 'styled-components';
import { BizMButtons } from '../types/template.types';
import { Link } from 'react-router-dom';

const Button = styled.div`
  background-color: #ededed;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 8px 8px 8px;

  a {
    text-decoration: none;
    color: black;
  }

  &.no-margin {
    margin: 0;
  }
`;

const ButtonPreview = ({
  text,
  buttonData,
  noMargin,
}: {
  buttonData: BizMButtons;
  text: string;
  noMargin?: boolean;
}) => {
  const btnLink = buttonData.url_pc ? buttonData.url_pc : buttonData.url_mobile;
  return (
    <Button className={noMargin ? 'no-margin' : ''}>
      {btnLink ? (
        <Link target="_blank" to={btnLink}>
          {text}
        </Link>
      ) : (
        text
      )}
    </Button>
  );
};

export default ButtonPreview;
