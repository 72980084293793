import styled from 'styled-components';
import { useContext } from 'react';
import { TemplateContext } from '../../contexts/templates.context';
import { TemplateCategoryEnum } from '../../enums/templateForm.enums';
import MessageContainer from '../messages/MessageContainer';
import PhoneTimeSignalRow from './PhoneTimeSignalRow';
import TopRow from './kakao/TopRow';
import DateRow from './kakao/DateRow';
import { Wrapper } from './kakao/kakao.style';
import Carousel from '../messages/Carousel';
import WideItemList from '../messages/WideItemList';

const KakaoInterface = styled.div`
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 7px;
`;

const CarouselWrapper = styled.div`
  width: 100%;
`;

const KakaoTemplate = () => {
  const { brandName, selectedTemplateCategory, currentTemplateData, initData } =
    useContext(TemplateContext);
  const isFriendtalk =
    selectedTemplateCategory?.label === TemplateCategoryEnum.FRIENDTALK;

  return (
    <KakaoInterface>
      <PhoneTimeSignalRow />
      <TopRow />
      <DateRow />
      {currentTemplateData && (
        <Wrapper imageUrl={initData.kakaoChannelProfileImage}>
          <div className="channel-row">
            <div className="channel-image" />
            <div className="brand-name">
              {isFriendtalk && <span className="ad">(AD)</span>}
              {brandName}
            </div>
          </div>
          {currentTemplateData.messageType === 'FC' &&
            currentTemplateData?.carousel && (
              <CarouselWrapper>
                <Carousel />
              </CarouselWrapper>
            )}
          {currentTemplateData.messageType == 'FL' &&
            currentTemplateData.templateWideItem && <WideItemList />}
          {currentTemplateData.messageType !== 'FC' &&
            currentTemplateData.messageType !== 'FL' && <MessageContainer />}
        </Wrapper>
      )}
    </KakaoInterface>
  );
};

export default KakaoTemplate;
