import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TemplateContext } from '../contexts/templates.context';
import { TemplateCategoryEnum } from '../enums/templateForm.enums';
import iphone from '../assets/device-frame.png';
import KakaoTemplate from './preview/KakaoTemplate';
import SmsTemplate from './preview/SmsTemplate';

const PreviewPhone = styled.div`
  background-image: url(${iphone});
  background-size: cover;
  width: 300px;
  height: auto;
  min-height: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 13px 18px;
  aspect-ratio: 260.49/507;
  background-color: white;

  &.isKakaoMessage {
    background-color: #afc0cf;
  }
`;

const Preview = () => {
  const { selectedTemplateCategory, currentTemplateData } =
    useContext(TemplateContext);
  const [isKakaoMessage, setIsKakaoMessage] = useState(false);
  const { messageType, templateCategory } = currentTemplateData ?? {};

  useEffect(() => {
    if (selectedTemplateCategory) {
      setIsKakaoMessage(
        selectedTemplateCategory.value === TemplateCategoryEnum.ALIMTALK ||
          selectedTemplateCategory.value === TemplateCategoryEnum.FRIENDTALK,
      );
    }
  }, [selectedTemplateCategory, messageType, templateCategory]);

  return (
    <PreviewPhone className={isKakaoMessage ? 'isKakaoMessage' : ''}>
      {isKakaoMessage && <KakaoTemplate />}
      {!isKakaoMessage && <SmsTemplate />}
    </PreviewPhone>
  );
};

export default Preview;
