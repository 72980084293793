import {
  useState,
  useEffect,
  useContext,
  useRef,
  MutableRefObject,
} from 'react';
import styled from 'styled-components';
import Column from './common/Column';
import Row from './common/Row';
import Button from './common/Button';
import Switch from 'react-switch';
import '../styles/journey.css';
import Loader from './Loader';
import eventBus from '../utils/eventbus';
import Modal from 'react-modal';
import TemplateModalContent from './TemplateModalContent';
import { FiArrowRight } from 'react-icons/fi';
import { FaList } from 'react-icons/fa';
import connection from '../utils/postmonger';
import MandatorySection from './MandatorySection';
import { TAKO_API_BASE_URL, TAKO_API_KEY } from '../config';
import {
  CustomVariablesOption,
  TemplateCategoryOption,
  TemplateOption,
} from '../types/jbForm.types';
import { SendMsgClientRequestBody } from '../types/sendMessageRequest.types';
import { TemplateContext } from '../contexts/templates.context';
import { CustomActivityContext } from '../contexts/customActivity.context';
import { Overlay } from './common/Overlay';
import { CustomActivityConfig } from '../types/CustomActivityConfig.types';
import { tempInitConfig } from '../contexts/tempInitConfig';
import axios from 'axios';
import { MessageSourceEnum } from '../enums/sendMessageRequest.enum';
import theme from '../styles/theme';
import MessageTypeSelect from './template_config/MessageTypeSelect';
import { TemplateCategoryEnum } from '../enums/templateForm.enums';
import { ToolTipContainer, Tooltip } from './tooltip/tooltip.styles';
import { VscTriangleUp } from 'react-icons/vsc';
import { TopButton } from './TopButton';
import help from '../assets/help.svg';
import DropdownSelect from './form/DropdownSelect';
import StyledInput from './form/StyledInput';
import { useIsOverflow } from '../hooks/isOverflow';
import { FormSectionWrap } from './form/FormSection';

const SubText = styled.div`
  color: #878787;
  font-size: 12px;
`;

const Container = styled.div`
  background: white;
  border-radius: 20px;
  padding: 46px 32px;
`;

const MessageCreationForm = () => {
  const {
    initData,
    allTemplatesData,
    selectedCategoryTemplatesData,
    changeTemplateListTemplates,
    selectedTemplateCategory,
    setSelectedTemplateCategory,
    templatesList,
    setTemplatesList,
    selectedTemplate,
    setSelectedTemplate,
    currentTemplateData,
    loading,
    templatesByCategories,
    smsTitle,
    setSmsTitle,
    messageHasImage,
    allowFallback,
    setAllowFallback,
    overrideVariables,
    setOverrideVariables,
    customVariableOptions,
    setCustomVariableOptions,
    activityDefaultValues,
    savedActivityData,
    setCurrentTemplateData,
  } = useContext(TemplateContext);

  const {
    activityConfig,
    setActivityConfig,
    journeyId,
    activityKey,
    eventDefinitionKey,
    journeyVersion,
  } = useContext(CustomActivityContext);

  const [showModal, setShowModal] = useState(false);
  const [templateFields, setTemplateFields] = useState<string[]>([]);

  const [selectedCustomVariables, setSelectedCustomVariables] = useState<
    number[]
  >([]);
  const [overrideMessageId, setOverrideMessageId] = useState(false);
  const [overridePhoneNumber, setOverridePhoneNumber] = useState(false);
  const [mandatoryMessageId, setMandatoryMessageId] = useState('');
  const [mandatoryPhoneNumber, setMandatoryPhoneNumber] = useState('');
  const [sendMessageRequestBody, setSendMessageRequestBody] =
    useState<SendMsgClientRequestBody>({} as SendMsgClientRequestBody);
  const [
    templateFieldValuesForRequestBody,
    setTemplateFieldValuesForRequestBody,
  ] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    if (!currentTemplateData) return;
    let fields = [] as string[];
    if (currentTemplateData['templateFields']) {
      fields = currentTemplateData['templateFields'];
    }

    setTemplateFields(fields);
    setSelectedTemplate({
      value: currentTemplateData['templateCode'],
      label: currentTemplateData['templateName'],
    });
  }, [currentTemplateData]);

  // useEffect(() => {
  //   const customVarIndexMatchArr = templateFields.map((value) => {
  //     const foundObj = customVariableOptions.find((obj) => obj.label === value);
  //     return foundObj ? customVariableOptions.indexOf(foundObj) : 0;
  //   });
  //   setSelectedCustomVariables(customVarIndexMatchArr);
  // }, [templateFields]);

  useEffect(() => {
    if (!loading) return;
    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    ) {
      // console.log('Setting up fake custom variables');
      setCustomVariableOptions([
        {
          value: 'Event.DEAudience-d7a55f34-0d15-149e-b05d-0804fd67d709.var1',
          label: 'eventName',
        },
        {
          value: 'Event.DEAudience-d7a55f34-0d15-149e-b05d-0804fd67d709.var2',
          label: 'cancelReason',
        },
        {
          value: 'Event.DEAudience-d7a55f34-0d15-149e-b05d-0804fd67d709.var3',
          label: 'customerFullName',
        },
        {
          value: 'Event.DEAudience-d7a55f34-0d15-149e-b05d-0804fd67d709.var4',
          label: 'reservationNo',
        },
      ]);
    }
    eventBus.on('receivedSchema', (data: CustomVariablesOption[]) => {
      // console.log('Received schema...', data);
      setCustomVariableOptions(data);
    });
    eventBus.on('closeTemplateModal', () => {
      setShowModal(false);
    });

    connection.trigger('ready');
    connection.trigger('requestEndpoints');
    connection.trigger('requestInteractionDefaults');
    connection.trigger('requestSchema');
  }, []);

  useEffect(() => {
    if (
      eventDefinitionKey &&
      savedActivityData &&
      activityDefaultValues &&
      customVariableOptions &&
      eventDefinitionKey === savedActivityData.eventDefinitionKey &&
      currentTemplateData?.templateCode === activityDefaultValues.templateCode
    ) {
      const templateFields =
        currentTemplateData && currentTemplateData.templateFields;
      const savedCustomVariables = activityDefaultValues.templateFields;
      if (templateFields) {
        const customVarsIndexArr = templateFields.map((field) => {
          const valueToMatch = savedCustomVariables[field];
          const matchedIndex = customVariableOptions.findIndex((obj) => {
            return `{{${obj.value}}}` === valueToMatch;
          });
          return matchedIndex !== -1 ? matchedIndex : 0;
        });
        setSelectedCustomVariables(customVarsIndexArr);
      }
    } else {
      const customVarIndexMatchArr = templateFields.map((value) => {
        const foundObj = customVariableOptions.find(
          (obj) => obj.label === value,
        );
        return foundObj ? customVariableOptions.indexOf(foundObj) : 0;
      });
      setSelectedCustomVariables(customVarIndexMatchArr);
    }
  }, [
    eventDefinitionKey,
    activityDefaultValues,
    savedActivityData,
    customVariableOptions,
    templateFields,
  ]);

  useEffect(() => {
    const requestBody = {} as SendMsgClientRequestBody;
    if (selectedTemplateCategory && currentTemplateData) {
      if (overrideMessageId) {
        requestBody.messageId = `{{${mandatoryMessageId}}}`;
      }
      requestBody.profileKey = currentTemplateData.fixedValues.profile_key;
      requestBody.brandCode = initData.brandCode;

      requestBody.messageType =
        currentTemplateData && currentTemplateData.messageType;
      requestBody.reservedTime = '00000000000000';
      requestBody.templateCode =
        currentTemplateData && currentTemplateData.templateCode;
      requestBody.receiverNum =
        mandatoryPhoneNumber === ''
          ? `{{Event.${eventDefinitionKey}.phoneNumber}}`
          : `{{${mandatoryPhoneNumber}}}`;
      requestBody.templateFields = templateFieldValuesForRequestBody;
      if (selectedTemplateCategory.value === TemplateCategoryEnum.SMS) {
        requestBody.smsOnly = 'Y';
      }
      if (allowFallback) {
        requestBody.smsOnly = 'N';
      }

      if (
        allowFallback ||
        selectedTemplateCategory.value === TemplateCategoryEnum.SMS
      ) {
        requestBody.smsKind = 'L';
        if (messageHasImage) requestBody.smsKind = 'M';
        if (smsTitle) requestBody.smsTitle = smsTitle;
        requestBody.senderNum = initData.kakaoDealer?.senderNumber;
      }
      requestBody.messageSource = MessageSourceEnum.SFMC_JB;

      console.log({ currentTemplateData, requestBody });
      console.log(
        'MESSAGE ID + PHONE NUMBER: ' +
          requestBody.messageId +
          ' ' +
          requestBody.receiverNum,
      );

      setSendMessageRequestBody(requestBody);
    }
  }, [
    customVariableOptions,
    currentTemplateData,
    templateFieldValuesForRequestBody,
    // selectedCustomVariables,
    smsTitle,
    overrideVariables,
    allowFallback,
    overridePhoneNumber,
    overrideMessageId,
    mandatoryMessageId,
    mandatoryPhoneNumber,
    messageHasImage,
  ]);

  useEffect(() => {
    if (currentTemplateData && currentTemplateData.templateFields) {
      let fields: { [key: string]: string } = {};
      fields = currentTemplateData.templateFields.reduce(
        (acc, field, index) => {
          acc[field] =
            overrideVariables && selectedCustomVariables.length > 0
              ? `{{${
                  customVariableOptions[selectedCustomVariables[index]]['value']
                }}}`
              : `{{Event.${eventDefinitionKey}.${field}}}`;
          return acc;
        },
        {} as { [key: string]: string },
      );
      setTemplateFieldValuesForRequestBody(fields);
    }
  }, [currentTemplateData, selectedCustomVariables, overrideVariables]);

  useEffect(
    function () {
      connection.off('clickedNext');
      connection.on('clickedNext', save);
    },
    [
      customVariableOptions,
      overrideVariables,
      selectedCustomVariables,
      selectedTemplate,
      smsTitle,
      allowFallback,
      initData,
      activityConfig,
      sendMessageRequestBody,
    ],
  );

  const onCategoryChange = (category: TemplateCategoryOption) => {
    setAllowFallback(false);
    setOverrideVariables(false);
    // setOverrideMandatoryFields(false);
    setSmsTitle(null);
    setSelectedTemplate(null);
    setTemplatesList(templatesByCategories[category.label]);
    setSelectedTemplateCategory(category);
    setCurrentTemplateData(null);
    setSendMessageRequestBody({} as SendMsgClientRequestBody);
    changeTemplateListTemplates(allTemplatesData, category);
  };

  const save = async function () {
    // console.log(JSON.stringify({ sendMessageRequestBody }));
    if (customVariableOptions.length === 0) {
      return;
    }

    let updatedActivityConfig = activityConfig || ({} as CustomActivityConfig);

    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    ) {
      updatedActivityConfig = tempInitConfig;
    }

    updatedActivityConfig.arguments.execute.body = JSON.stringify(
      sendMessageRequestBody,
    );
    updatedActivityConfig.metaData.isConfigured = true;

    const activityCurrentState = {
      ...sendMessageRequestBody,
      allowFallback,
      overrideVariables,
    };

    const saveActivityDefaultValues = {
      journeyId,
      activityKey,
      activityDefaultValues: JSON.stringify(activityCurrentState),
      eventDefinitionKey,
      journeyVersion,
    };

    setActivityConfig(updatedActivityConfig);

    if (journeyId && activityKey) {
      try {
        await axios.post(
          `${TAKO_API_BASE_URL}/sfmc-jb-custom-activity/saved-activity`,
          saveActivityDefaultValues,
          {
            headers: {
              'x-api-key': TAKO_API_KEY,
            },
          },
        );
        // console.log({ saveActivityDefault: response.data });
      } catch (error) {
        console.log({ error });
      }
    }

    // if (
    // window.location.hostname !== 'localhost' &&
    // window.location.hostname !== '127.0.0.1'
    // ) {
    // console.log('====updateActivity....(not localhost).');
    connection.trigger('updateActivity', updatedActivityConfig);
    // console.log({ updatedActivityConfig });
    // console.log('update config done');
    // }
    // console.log({ saveActivityDefaultValues });
    // console.log('============save done=============');
  };

  const onTemplateChange = (template: TemplateOption | null) => {
    if (template) {
      setSmsTitle(null);
      setSelectedTemplate(template);
      setOverrideVariables(false);
    }
  };

  const changeCustomVariable = (
    variable: CustomVariablesOption,
    index: number,
  ) => {
    const newIndex = customVariableOptions.indexOf(variable);
    setSelectedCustomVariables((prevIndexes) => {
      const newIndexes = [...prevIndexes];
      newIndexes[index] = newIndex;
      return newIndexes;
    });
  };

  const messageTypeSelectRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef() as MutableRefObject<HTMLDivElement>;
  const isOverflow = useIsOverflow({ ref: scrollRef });

  return (
    <div
      style={{ overflow: showModal ? 'hidden' : '', minHeight: '100%' }}
      className="hello"
    >
      <Column>
        <div ref={scrollRef}>
          {loading && (
            <Overlay>
              <Loader />
            </Overlay>
          )}
          <div ref={messageTypeSelectRef}>
            <MessageTypeSelect onCategoryChange={onCategoryChange} />
          </div>
          <Container
            style={{
              position: 'relative',
              marginTop: '15px',
            }}
          >
            <Column>
              <FormSectionWrap>
                <div className="label-wrap">
                  <div className="label-text">Select template</div>
                  <Button
                    $bold
                    padding="5px 15px"
                    size="12px"
                    color={theme.colors.primary}
                    onClick={() => setShowModal(true)}
                    style={{ gap: '10px' }}
                  >
                    <FaList style={{ fontSize: '14px' }} />
                    <div style={{ fontWeight: 'bold' }}>TEMPLATE LIST</div>
                  </Button>
                </div>

                <DropdownSelect
                  value={selectedTemplate}
                  options={templatesList}
                  placeholder="Select template"
                  onChange={onTemplateChange}
                  isSearch={true}
                />
              </FormSectionWrap>
              <FormSectionWrap>
                {selectedTemplateCategory &&
                  selectedTemplateCategory.label !== TemplateCategoryEnum.SMS &&
                  initData.kakaoDealer?.senderNumber && (
                    <>
                      <div className="label-wrap">
                        <div className="label-text-wrap">
                          <div
                            className="label-text"
                            style={{ marginRight: '5px' }}
                          >
                            Fallback message
                          </div>
                          <Tooltip>
                            <img src={help} />
                            <ToolTipContainer>
                              A fallback message is a backup message (LMS or
                              MMS) sent when the Alimtalk or Friendtalk fails to
                              send through Kakao.
                            </ToolTipContainer>
                          </Tooltip>
                        </div>
                        <Switch
                          checked={allowFallback}
                          onChange={setAllowFallback}
                          onColor={theme.colors.primary}
                          onHandleColor="#fff"
                          activeBoxShadow="0 0 0px 0px #FFF"
                          width={34}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          handleDiameter={16}
                          height={20}
                          offColor="#D9D9D9"
                        />
                      </div>

                      {allowFallback && initData.kakaoDealer.senderNumber && (
                        <StyledInput
                          value={initData.kakaoDealer.senderNumber}
                          disabled={true}
                          marginBottom="10px"
                        />
                      )}
                    </>
                  )}

                {selectedTemplateCategory &&
                  selectedTemplateCategory.label === TemplateCategoryEnum.SMS &&
                  initData.kakaoDealer?.senderNumber && (
                    <div
                      className="label-text"
                      style={{ marginBottom: '10px' }}
                    >
                      Title
                    </div>
                  )}
                {(allowFallback ||
                  (selectedTemplateCategory &&
                    selectedTemplateCategory.label ===
                      TemplateCategoryEnum.SMS)) &&
                  initData.kakaoDealer?.senderNumber && (
                    <StyledInput
                      value={smsTitle ? smsTitle : ''}
                      placeholder={
                        selectedTemplateCategory &&
                        selectedTemplateCategory.label ===
                          TemplateCategoryEnum.SMS
                          ? 'Enter a title for the message'
                          : 'Enter title for the fallback message'
                      }
                      onChange={(e) => {
                        setSmsTitle(e.target.value);
                      }}
                    />
                  )}
              </FormSectionWrap>

              {templateFields.length != 0 && (
                <FormSectionWrap>
                  <div className="label-wrap">
                    <div className="label-text">Use custom variables</div>
                    <Row>
                      <SubText style={{ marginRight: '10px' }}>
                        enable custom variables for template fields
                      </SubText>
                      <Switch
                        checked={overrideVariables}
                        onChange={setOverrideVariables}
                        onColor={theme.colors.primary}
                        onHandleColor="#fff"
                        activeBoxShadow="0 0 0px 0px #FFF"
                        width={34}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={16}
                        className={overrideVariables ? 'switch--active' : ''}
                        height={20}
                        offColor="#D9D9D9"
                      />
                    </Row>
                  </div>

                  {overrideVariables &&
                    templateFields.map((field, index) => (
                      <Row key={'variable_' + index} $spaceBetween>
                        <StyledInput
                          value={'#' + field}
                          width="45%"
                          readOnly={true}
                        />
                        <FiArrowRight />
                        <DropdownSelect
                          value={
                            customVariableOptions[
                              selectedCustomVariables[index]
                            ]
                          }
                          onChange={(v) => {
                            v && changeCustomVariable(v, index);
                          }}
                          options={customVariableOptions}
                          width="45%"
                        />
                      </Row>
                    ))}
                </FormSectionWrap>
              )}
              <FormSectionWrap>
                <MandatorySection
                  options={customVariableOptions}
                  setMandatoryPhoneNumber={setMandatoryPhoneNumber}
                  setMandatoryMessageId={setMandatoryMessageId}
                  overrideMessageId={overrideMessageId}
                  overridePhoneNumber={overridePhoneNumber}
                  setOverrideMessageId={setOverrideMessageId}
                  setOverridePhoneNumber={setOverridePhoneNumber}
                />
              </FormSectionWrap>
            </Column>
          </Container>
        </div>
      </Column>
      <TopButton
        id="top-button"
        onClick={() => {
          if (messageTypeSelectRef.current) {
            messageTypeSelectRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        }}
        className={isOverflow ? '' : 'is-overflow'}
      >
        <div>
          <VscTriangleUp />
          TOP
        </div>
      </TopButton>
      <Modal
        style={{
          content: {
            zIndex: 45,
          },
        }}
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Example Modal"
      >
        <TemplateModalContent templates={selectedCategoryTemplatesData} />
      </Modal>
    </div>
  );
};

export default MessageCreationForm;
