import Row from './common/Row';
import styled from 'styled-components';
import Switch from 'react-switch';
import StyledInput from './form/StyledInput';
import { FiArrowRight } from 'react-icons/fi';
import { SingleValue } from 'react-select';
import { CustomVariablesOption } from '../types/jbForm.types';
import theme from '../styles/theme';
import DropdownSelect from './form/DropdownSelect';
import { FormSectionWrap } from './form/FormSection';

const SubText = styled.div`
  color: #878787;
  font-size: 12px;
  margin-right: 10px;
`;

const WarningContainer = styled.div`
  background: #eff0f5;
  font-size: 12px;
  padding: 20px;
  margin-bottom: 16px;

  .red {
    color: #fa6d6d;
  }

  .bullet {
    margin-left: 10px;
  }

  ul {
    margin-top: 0px;
    padding-left: 20px;

    &:last-of-type {
      margin: 0;
    }
  }
`;

const ErrorMessage = styled.div`
  color: #fa6d6d;
  font-size: 11px;
`;

const MandatorySection = ({
  options,
  setMandatoryMessageId,
  setMandatoryPhoneNumber,
  overridePhoneNumber,
  setOverridePhoneNumber,
  overrideMessageId,
  setOverrideMessageId,
}: {
  options: CustomVariablesOption[];
  setMandatoryPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  setMandatoryMessageId: React.Dispatch<React.SetStateAction<string>>;
  overridePhoneNumber: boolean;
  setOverridePhoneNumber: React.Dispatch<React.SetStateAction<boolean>>;
  overrideMessageId: boolean;
  setOverrideMessageId: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const onMessageIdChange = function (
    newValue: SingleValue<CustomVariablesOption>,
  ) {
    if (newValue) {
      setMandatoryMessageId(newValue.value);
    }
  };
  const onPhoneNumberChange = function (
    phoneNumberOption: SingleValue<CustomVariablesOption>,
  ) {
    if (phoneNumberOption) {
      setMandatoryPhoneNumber(phoneNumberOption.value);
    }
  };

  return (
    <FormSectionWrap>
      <div className="label-text">Required fields</div>
      <div className="label-wrap">
        <div className="label-text label-text--primary">Message ID</div>
        <div className="label-text-wrap">
          <SubText>enable custom variables for messageId</SubText>
          <Switch
            checked={overrideMessageId}
            onChange={setOverrideMessageId}
            onColor={theme.colors.primary}
            onHandleColor="#fff"
            activeBoxShadow="0 0 0px 0px #FFF"
            width={34}
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={16}
            className={overrideMessageId ? 'switch--active' : ''}
            height={20}
            offColor="#D9D9D9"
          />
        </div>
      </div>
      {overrideMessageId && (
        <>
          <WarningContainer>
            The following fields are required fields for every message to be
            sent:
            <br />
            <br />
            <span style={{ color: theme.colors.primary, fontWeight: 'bold' }}>
              messageId
            </span>
            : Max 20 characters. Unique message ID.
            <ul>
              <li>
                Recommended format of messageId is
                “&#123;BRANDCODE&#125;&#123;serial number&#125;” with no spaces
                in between.
              </li>
              <li>
                All message IDs must be unique, otherwise message will fail to
                send.
              </li>
              <li>
                If you don&#39;t pass a messageId, a unique messageId will be
                generated for each message for you.
              </li>
            </ul>
          </WarningContainer>

          <span>
            <Row $spaceBetween>
              <StyledInput value="messageId" width="45%" readOnly={true} />
              <FiArrowRight />
              <DropdownSelect
                placeholder="messageId"
                onChange={onMessageIdChange}
                options={options}
                width="45%"
              />
            </Row>
            <ErrorMessage>
              messageId must be unique. Otherwise, message will fail to send due
              to duplicate messageId.
            </ErrorMessage>
          </span>
        </>
      )}

      <div className="label-wrap">
        <div className="label-text label-text--primary">Phone number</div>
        <div className="label-text-wrap">
          <SubText>enable custom variables for phoneNumber</SubText>
          <Switch
            checked={overridePhoneNumber}
            onChange={setOverridePhoneNumber}
            onColor={theme.colors.primary}
            onHandleColor="#fff"
            activeBoxShadow="0 0 0px 0px #FFF"
            width={34}
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={16}
            className={overridePhoneNumber ? 'switch--active' : ''}
            height={20}
            offColor="#D9D9D9"
          />
        </div>
      </div>
      {overridePhoneNumber && (
        <>
          <WarningContainer>
            The following fields are required fields for every message to be
            sent:
            <br />
            <br />
            <span style={{ color: theme.colors.primary, fontWeight: 'bold' }}>
              phoneNumber
            </span>{' '}
            : 11 digit Korean mobile number starting with 010, no hyphens or
            spaces allowed between characters.
            <ul>
              <li>
                For international numbers, must add &#39;+&#39; in front of
                country code.
              </li>
              <li>
                (O) Validphone number formats: ex) &#39;01012345678&#39;
                &#39;821012345678&#39; &#39;+821012345678&#39;
              </li>
              <li>
                (X) Invalid phone number formats: ex) ’+8201090724270’ or
                anything with space or hyphens in between characters
              </li>
            </ul>
          </WarningContainer>

          <Row $spaceBetween>
            <StyledInput value="phoneNumber" width="45%" readOnly={true} />
            <FiArrowRight />
            <DropdownSelect
              placeholder="phoneNumber"
              onChange={onPhoneNumberChange}
              options={options}
              width="45%"
            />
          </Row>
        </>
      )}
    </FormSectionWrap>
  );
};

export default MandatorySection;
