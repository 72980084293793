import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { CarouselContainer, More } from './Message.style';
import { BsArrowRight } from 'react-icons/bs';
import ButtonPreview from '../ButtonPreview';
import { TemplateContext } from '../../contexts/templates.context';
import { useContext } from 'react';

const Carousel = () => {
  const { currentTemplateData } = useContext(TemplateContext);
  if (!currentTemplateData?.carousel) return <></>;
  return (
    <CarouselContainer>
      <Swiper
        slidesPerView={1.23}
        spaceBetween={5}
        onSlideChange={(swiper: SwiperClass) => {
          const index = swiper.activeIndex;
          const length = swiper.slides.length;
          swiper.allowSlideNext = index !== length - 2;
        }}
      >
        {currentTemplateData.carousel.list.map((value, index) => (
          <SwiperSlide key={'slide_' + index}>
            <img src={value.image.imageUrl} />
            <div className="content">
              <div className="title">{value.header}</div>
              <div className="desc">{value.message}</div>
            </div>
            {value.buttons &&
              value.buttons.map((button) => (
                <ButtonPreview
                  key={`slide-${button.name}`}
                  text={button.name}
                  buttonData={button}
                />
              ))}
          </SwiperSlide>
        ))}

        {currentTemplateData.carousel.tail && (
          <SwiperSlide className="more">
            <More>
              <BsArrowRight size="21px" />
              <span>더보기</span>
            </More>
          </SwiperSlide>
        )}
        <SwiperSlide />
      </Swiper>
    </CarouselContainer>
  );
};

export default Carousel;
