import { styled } from 'styled-components';
import theme from '../../styles/theme';

export const MessageTypeSelectContainer = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid ${theme.colors.primary};
  border-radius: 50px;
  overflow: hidden;
`;

export const MessageTypeButton = styled.div<{
  $middle?: boolean;
  $selected?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 16px;
  flex: 1;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.1em;
  cursor: pointer;
  font-size: 15px;
  background: ${(props) => (props.$selected ? theme.colors.primary : 'white')};
  color: ${(props) => (props.$selected ? 'white' : theme.colors.primary)};

  &.isMiddle {
    border-left: 2px solid ${theme.colors.primary};
    border-right: 2px solid ${theme.colors.primary};
  }
`;
