import { IoCalendarOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { DateRowStyled } from './kakao.style';
import { BiHomeAlt2 } from 'react-icons/bi';

const DateRow = () => {
  const today = new Date();
  const days = [
    '일요일',
    '월요일',
    '화요일',
    '수요일',
    '목요일',
    '금요일',
    '토요일',
  ];
  return (
    <DateRowStyled>
      <div style={{ width: '26px' }} />
      <div className="date-container">
        <IoCalendarOutline style={{ marginRight: '5px' }} />
        {today.getFullYear()}년 {today.getMonth() + 1}월 {today.getDate()}일{' '}
        {days[today.getDay()]} <IoChevronForwardOutline />
      </div>
      <div className="icon-container">
        <BiHomeAlt2 />
      </div>
    </DateRowStyled>
  );
};

export default DateRow;
