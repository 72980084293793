import styled from 'styled-components';
import Row from '../common/Row';
import PhoneTimeSignalRow from './PhoneTimeSignalRow';
import { IoChevronBackOutline, IoPersonCircle } from 'react-icons/io5';
import { useContext } from 'react';
import { TemplateContext } from '../../contexts/templates.context';
import Column from '../common/Column';
import MessageContainer from '../messages/MessageContainer';

const SmsInterface = styled.div`
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const SmsTemplate = () => {
  const { initData, currentTemplateData } = useContext(TemplateContext);
  return (
    <SmsInterface>
      <PhoneTimeSignalRow isSms={true} />
      <Row
        $spaceBetween
        style={{ padding: '10px', borderBottom: '1px solid #d4d4d4' }}
      >
        <IoChevronBackOutline
          style={{ color: '#3781df', height: '22px', width: '22px' }}
        />
        <Column $alignCenter>
          <IoPersonCircle
            style={{ height: '50px', width: '50px', color: '#8a8d95' }}
          />
          {initData.kakaoDealer?.senderNumber && (
            <Row $center style={{ fontSize: '10px' }}>
              {initData.kakaoDealer.senderNumber}
            </Row>
          )}
        </Column>
        <div style={{ width: '22px' }} />
      </Row>
      {currentTemplateData && (
        <Row
          $alignStart
          style={{
            background: 'white',
            height: '434px',
            borderBottomLeftRadius: '28px',
            borderBottomRightRadius: '28px',
            padding: '10px',
            overflowY: 'auto',
            display: 'flex',
            flexGrow: 1,
          }}
        >
          <MessageContainer />
        </Row>
      )}
    </SmsInterface>
  );
};

export default SmsTemplate;
