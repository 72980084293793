import styled from 'styled-components';
import theme from '../../styles/theme';

interface IInputContainer {
  width?: string;
  $marginBottom?: string;
}

const InputContainer = styled.input<IInputContainer>`
  border: 1px solid #00000050;
  border-radius: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  font-family: 'Poppins';
  font-size: 13px;
  box-sizing: border-box;
  height: 43px;
  font-size: 13px;
  width: ${({ width }) => (width ? width : '100%')};
  padding: 12px 16px;
  margin-bottom: ${({ $marginBottom }) =>
    $marginBottom ? $marginBottom : '10px'};

  &.disabled {
    color: ${theme.colors.gray};
    border: 1px solid ${theme.colors.gray_light1};
    background: ${theme.colors.gray_light2};
  }

  &:read-only {
    cursor: auto;

    &:focus-visible {
      outline: none;
    }
  }

  /* &:last-of-type {
    margin-bottom: ${({ $marginBottom }) =>
    $marginBottom ? $marginBottom : 0};
  } */
`;

interface IVariableContainer {
  marginBottom?: string;
  value?: string;
  width?: string;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}
const StyledInput = ({
  value,
  placeholder,
  width,
  disabled = false,
  readOnly = false,
  onChange,
  marginBottom,
}: IVariableContainer) => {
  return (
    <InputContainer
      value={value}
      placeholder={placeholder}
      width={width}
      className={disabled ? 'disabled' : ''}
      readOnly={readOnly}
      disabled={disabled}
      onChange={onChange}
      $marginBottom={marginBottom}
    />
  );
};

export default StyledInput;
