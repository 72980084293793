import { useContext, useRef, useState } from 'react';
import eventBus from '../utils/eventbus';
import Column from './common/Column';
import Row from './common/Row';
import { GrClose } from 'react-icons/gr';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getFilteredRowModel,
  Column as ColumnType,
  Cell,
} from '@tanstack/react-table';
import { styled } from 'styled-components';
import { BizMTemplateEntity } from '../types/template.types';
import ButtonTooltipContent from './tooltip/ButtonTooltipContent';
import MessageTooltipContent from './tooltip/MessageTooltipContent';
import { Tooltip } from './tooltip/tooltip.styles';
import theme from '../styles/theme';
import {
  IoChevronBackSharp,
  IoChevronForwardOutline,
  IoChevronForwardSharp,
  IoSearch,
} from 'react-icons/io5';
import { TemplateContext } from '../contexts/templates.context';

type Template = {
  highlight?: boolean;
};

type CombinedTemplateType = BizMTemplateEntity & Template;

const MessageContent = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid ${theme.colors.gray_light1};
  border-top: 1px solid ${theme.colors.gray_light1};
  cursor: pointer;
  font-size: 12px;
  line-height: 18px;
  &:hover {
    background: ${theme.colors.primary_light5};
  }
  &:hover svg {
    color: ${theme.colors.primary_dark} !important;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  margin-bottom: 20px;
  width: 100%;

  #input-wrapper {
    position: relative;
    margin-right: 10px;

    input {
      padding: 10px 10px 10px 35px;
      border-radius: 5px;
      border: 1px solid ${theme.colors.gray_light1};
      font-family: Poppins;
    }
    #search-icon {
      position: absolute;
      font-size: 20px;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  #close-icon {
    cursor: pointer;
    font-size: 20px;
    margin-left: 30px;
  }
`;

const TableWrapper = styled.table`
  border-collapse: collapse;
  flex-grow: 1;
  td {
    padding: 10px;
    text-align: center;
    max-width: 300px;
  }
  tbody:before {
    content: '-';
    line-height: 15px;
    color: transparent;
  }
`;

const PageNumber = styled.div<{ $active?: boolean }>`
  color: ${(props) =>
    props.$active ? theme.colors.black : theme.colors.primary_light3};
  margin: 0 10px;
  cursor: pointer;
  font-size: 13px;
`;

const Table = ({
  data,
  columns,
  globalFilter,
  setGlobalFilter,
}: {
  data: CombinedTemplateType[];
  columns: ColumnType<CombinedTemplateType, unknown>[];
  globalFilter: string;
  setGlobalFilter: (arg0: string) => void;
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: { pagination: { pageSize: 10, pageIndex: 0 } },
    debugTable: true,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
  });

  const { setSelectedTemplate } = useContext(TemplateContext);

  const backNumbers = function () {
    const elems = [];
    for (let i = 0; i < table.getState().pagination.pageIndex; ++i) {
      elems.push(
        <PageNumber onClick={() => table.setPageIndex(i)}>{i + 1}</PageNumber>,
      );
    }
    return elems;
  };

  const forwardNumbers = function () {
    const elems = [];
    for (
      let i = table.getState().pagination.pageIndex + 2;
      i < table.getPageCount() + 1;
      ++i
    ) {
      elems.push(
        <PageNumber onClick={() => table.setPageIndex(i - 1)}>{i}</PageNumber>,
      );
    }
    return elems;
  };

  return (
    <Column $grow style={{ paddingBottom: '20px' }}>
      <Row $grow $alignStart>
        <TableWrapper>
          <thead>
            {table &&
              table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        fontSize: '12px',
                        color: theme.colors.gray_dark2,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </th>
                  ))}
                </tr>
              ))}
          </thead>
          <tbody>
            {table &&
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => {
                    setSelectedTemplate({
                      value: row.original.templateCode,
                      label: row.original.templateName,
                    });
                    eventBus.dispatch('closeTemplateModal', {});
                  }}
                >
                  {row
                    .getVisibleCells()
                    .map(
                      (
                        cell: Cell<
                          CombinedTemplateType,
                          keyof CombinedTemplateType
                        >,
                      ) => {
                        return (
                          <td key={cell.id}>
                            <Tooltip
                              style={{
                                textAlign:
                                  cell.column.id === 'message'
                                    ? 'start'
                                    : 'center',
                              }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                              {cell.column.id === 'message' &&
                                cell.getValue() && (
                                  <MessageTooltipContent
                                    cell={cell}
                                    row={row}
                                  />
                                )}
                              {cell.column.id === 'buttonsTotal' &&
                                Number.parseInt(cell.getValue()) !== 0 && (
                                  <ButtonTooltipContent row={row} />
                                )}
                            </Tooltip>
                          </td>
                        );
                      },
                    )}
                </TableRow>
              ))}
          </tbody>
          <tfoot>
            {table &&
              table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext(),
                          )}
                    </th>
                  ))}
                </tr>
              ))}
          </tfoot>
        </TableWrapper>
      </Row>

      <Row $center $margin="20px 0">
        {table && (
          <IoChevronBackSharp
            style={{
              margin: '0 10px',
              fontSize: '18px',
              color: table.getCanPreviousPage()
                ? theme.colors.black
                : theme.colors.primary_light3,
            }}
            onClick={() => {
              if (table.getState().pagination.pageIndex === 0) return;
              table.previousPage();
            }}
          />
        )}

        {table &&
          table.getState().pagination.pageIndex > 0 &&
          backNumbers().map((e) => e)}
        {table && (
          <PageNumber $active>
            {table.getState().pagination.pageIndex + 1}
          </PageNumber>
        )}

        {table &&
          table.getState().pagination.pageIndex + 2 <= table.getPageCount() &&
          forwardNumbers().map((e) => e)}

        {table && (
          <IoChevronForwardSharp
            style={{
              margin: '0 10px',
              fontSize: '18px',
              color: table.getCanNextPage()
                ? theme.colors.black
                : theme.colors.primary_light3,
            }}
            onClick={() => {
              if (
                table.getState().pagination.pageIndex ===
                table.getPageCount() - 1
              )
                return;
              table.nextPage();
            }}
          />
        )}
      </Row>
    </Column>
  );
};

type TemplateModalProps = { templates: { [key: string]: BizMTemplateEntity } };

const TemplateModalContent = ({ templates }: TemplateModalProps) => {
  const data = Object.values(templates);
  const [globalFilter, setGlobalFilter] = useState('');
  const columnHelper = createColumnHelper<CombinedTemplateType>();
  const textInput = useRef<HTMLInputElement>(null);
  const [activeSearch, setActiveSearch] = useState(false);

  const columns = [
    columnHelper.accessor('creationDate', {
      cell: (info) => info.getValue(),
      header: () => 'CREATION DATE',
    }),
    columnHelper.accessor('templateCode', {
      cell: (info) => info.getValue(),
      header: () => 'TEMPLATE CODE',
    }),
    columnHelper.accessor('templateName', {
      cell: (info) => info.getValue(),
      header: () => 'TEMPLATE NAME',
    }),
    columnHelper.accessor('messageType', {
      cell: (info) => info.getValue(),
      header: () => 'MESSAGE TYPE',
    }),
    columnHelper.accessor('message', {
      cell: (info) => <MessageContent>{info.getValue()}</MessageContent>,
      header: () => 'MESSAGE CONTENT',
      maxSize: 300,
    }),
    columnHelper.accessor('buttonsTotal', {
      header: 'HIGHTLIGHT / BUTTON',
      cell: (props) => (
        <Row $spaceAround>
          {/*eslint-disable-next-line react/prop-types*/}
          <div> {props.row.original.highlight ? 'Y' : 'N'}</div>{' '}
          {/*eslint-disable-next-line react/prop-types*/}
          <div>{props.row.original.buttonsTotal}</div>{' '}
        </Row>
      ),
    }),
    columnHelper.accessor(() => `>`, {
      id: 'uselessArrow',
      header: undefined,
      cell: () => (
        <IoChevronForwardOutline
          style={{
            fontSize: '20px',
            color: theme.colors.gray_light1,
            margin: '0 20px',
          }}
        />
      ),
    }),
  ] as ColumnType<CombinedTemplateType>[];

  return (
    <Column style={{ height: '100%' }}>
      <ModalWrapper>
        <h2>TEMPLATE LIST</h2>
        <Row>
          {activeSearch && (
            <div id="input-wrapper">
              <input
                ref={textInput}
                value={globalFilter ?? ''}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                }}
                placeholder="Search"
                type="text"
              />
              <IoSearch id="search-icon" />
            </div>
          )}
          {!activeSearch && (
            <IoSearch
              style={{ fontSize: '20px', cursor: 'pointer' }}
              onClick={() => setActiveSearch(true)}
            />
          )}

          <GrClose
            id="close-icon"
            onClick={() => {
              eventBus.dispatch('closeTemplateModal', {});
            }}
          />
        </Row>
      </ModalWrapper>
      <Table
        data={data}
        columns={columns}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
    </Column>
  );
};

export default TemplateModalContent;
