import { IoBatteryFullSharp, IoCellular } from 'react-icons/io5';
import { MdWifi } from 'react-icons/md';
import { styled } from 'styled-components';
import Row from '../common/Row';

const PhoneTimeSignalWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px 10px;
  font-weight: bold;
  font-size: 12px;
  font-family: 'Pretendard Variable', Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI',
    'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;

  &.sms-phone-time {
    /* padding: 10px 7px; */
    padding: 10px 19px 10px;
    /* padding: 20px; */
  }
`;

interface IPhoneTimeSignalRowProps {
  isSms?: boolean;
}
const PhoneTimeSignalRow = ({ isSms }: IPhoneTimeSignalRowProps) => {
  const today = new Date();
  return (
    <PhoneTimeSignalWrap className={isSms ? 'sms-phone-time' : ''}>
      <div>
        {today.getHours().toString().padStart(2, '0')}:
        {today.getMinutes().toString().padStart(2, '0')}
      </div>
      <Row style={{ gap: '3px' }}>
        <IoCellular />
        <MdWifi />
        <IoBatteryFullSharp />
      </Row>
    </PhoneTimeSignalWrap>
  );
};

export default PhoneTimeSignalRow;
