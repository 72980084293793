import { styled } from 'styled-components';
import theme from '../../styles/theme';

export const FormSectionWrap = styled.div`
  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .label-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    margin-top: 24px;
  }
  .label-text-wrap {
    display: flex;
    align-items: center;
  }
  .label-text {
    font-weight: bold;
    &--primary {
      color: ${theme.colors.primary_light1};
    }
  }
`;

// const FormSection = ({ children }: { children: ReactNode }) => {
//   return <div>{children}</div>;
// };

// export default FormSection;
