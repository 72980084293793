import styled from 'styled-components';
import kakao from '../../assets/kakao.svg';

export const MessageWrap = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  border-radius: 15px;
  position: relative;
  background: #f9f9f9;
  width: 86%;
  margin-left: 24px;

  &.sms-message-wrap {
    background: #fff;
  }

  .alimtalk-header {
    content: 'AlimTalk';
    box-sizing: border-box;
    background-color: #fbe54d;
    width: 100%;
    padding: 8px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
`;

export const KakaoMessage = styled.div`
  padding: 10px 8px;
  font-weight: 500;
  flex-grow: 1;
  position: relative;
  white-space: pre-line;
  margin-bottom: 10px;
  word-break: break-all;
`;

export const AlimtalkSender = styled.div`
  font-size: 0.8rem;
  color: #888888;
  margin-bottom: 8px;
`;

export const AlimtalkHeader = styled.div`
  content: 'AlimTalk';
  box-sizing: border-box;
  background-color: #fbe54d;
  width: 100%;
  padding: 8px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  &:after {
    background-image: url(${kakao});
    background-size: cover;
    content: '';
    color: white;
    border-radius: 50%;
    font-size: 5px;
    height: 20px;
    width: 20px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -10px;
    top: -10px;
  }
`;

export const ImgStyled = styled.img`
  width: 100%;
  object-fit: contain;
  border-radius: 15px 15px 0 0;

  &.smsImage {
    border-radius: 15px;
    margin-left: 10px;
    width: calc(100% - 10px);
  }
`;

export const EmphasizeTypeText = styled.div`
  padding: 24px 0px;
  margin: 0px 15px;
  border-bottom: 1px solid #c2c2c2;

  .emphTitle {
    font-size: 0.95rem;
  }
  .emphSubTitle {
    color: #888888;
    font-size: 0.8rem;
  }
`;

export const SmsMessageWrap = styled.div`
  flex-grow: 1;
  white-space: pre-line;
  word-break: break-all;
  align-items: flex-start;
  background-color: #eee;
  position: relative;
  margin-left: 10px;
  border-radius: 20px;
  padding: 8px 15px;
  display: inline-block;
  margin-bottom: 3px;

  &.sms-title {
    font-weight: 600;
    width: fit-content;
  }

  &.last-message {
    min-height: 30px;
    &::before {
      content: '';
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: -7px;
      height: 20px;
      width: 20px;
      background: #eee;
      border-bottom-right-radius: 15px;
    }
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: -10px;
      width: 10px;
      height: 20px;
      background: #fff;
      border-bottom-right-radius: 10px;
    }
  }
`;

export const CarouselContainer = styled.div`
  .swiper {
    z-index: 0;
  }
  .swiper-wrapper {
    display: flex;
    align-items: stretch;
  }
  .swiper-slide:last-of-type {
    width: 0 !important;
  }
  .swiper-slide {
    &:first-of-type {
      margin-left: 25px;
    }
    &:last-of-type {
      margin-right: 25px !important;
    }
    background-color: #f9f9f9;
    border-radius: 15px;
    overflow: hidden;
    padding-bottom: 8px;
    height: auto;
    img {
      max-width: 100%;
    }
    .content {
      padding: 0 8px;
      font-size: 12px;
      .title {
        padding: 8px 0;
        text-transform: uppercase;
        font-weight: 700;
        border-bottom: 1px solid #eee;
      }
      .desc {
        padding: 8px 0;
        white-space: pre-line;
        word-break: break-all;
      }
    }
    &.more {
      background: transparent;
      display: flex;
      align-items: center;
    }
  }
`;

export const More = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  span {
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const WideItemListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border-radius: 15px;
  overflow: hidden;
  padding: 8px;
  width: 90%;
  margin-left: 24px;
  .header {
    font-size: 13px;
    font-weight: 600;
    white-space: pre-line;
    word-break: break-all;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 8px;
  }
  .first-item {
    position: relative;
    margin-bottom: 13px;
    img {
      height: 75px;
      width: 100%;
      object-fit: cover;
    }
    .title {
      position: absolute;
      bottom: 4px;
      left: 8px;
      color: white;
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.51);
    }
  }
  .item {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 13px;
    img {
      height: 40px;
      width: 40px;
    }
    .title {
      font-size: 0.8em;
    }
  }
  .button-row {
    display: flex;
    gap: 4px;
    & > div {
      flex-grow: 1;
      margin: 0;
      font-size: 12px;
    }
  }
`;
