import { IoChevronBackOutline, IoSearchOutline } from 'react-icons/io5';
import { KakaoInterfaceTop } from './kakao.style';
import { RxHamburgerMenu } from 'react-icons/rx';
import { TemplateContext } from '../../../contexts/templates.context';
import { useContext } from 'react';

const TopRow = () => {
  const { brandName } = useContext(TemplateContext);
  return (
    <KakaoInterfaceTop>
      <div className="kk-interface-top-icons left">
        <IoChevronBackOutline />
        <div>21</div>
      </div>
      {brandName}
      <div className="kk-interface-top-icons right">
        <IoSearchOutline />
        <RxHamburgerMenu />
      </div>
    </KakaoInterfaceTop>
  );
};

export default TopRow;
