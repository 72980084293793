import { useContext, useEffect, useState } from 'react';
import { WideItemListContainer } from './Message.style';
import { TemplateContext } from '../../contexts/templates.context';
import { BizMButtons, BizMTemplateEntity } from '../../types/template.types';
import ButtonPreview from '../ButtonPreview';

const WideItemList = () => {
  const { currentTemplateData } = useContext(TemplateContext);
  const [buttons, setButtons] = useState<BizMButtons[]>([]);
  useEffect(() => {
    const buttonsArr = [] as BizMButtons[];
    if (currentTemplateData && currentTemplateData.buttonsTotal) {
      for (let i = 1; i <= currentTemplateData.buttonsTotal; ++i) {
        const buttonNum = `button${i}` as keyof BizMTemplateEntity;
        buttonsArr.push(currentTemplateData[buttonNum] as BizMButtons);
      }
    }
    setButtons(buttonsArr);
  }, [currentTemplateData]);

  return (
    <WideItemListContainer>
      {currentTemplateData?.templateHeader && (
        <div className="header">{currentTemplateData.templateHeader}</div>
      )}
      {currentTemplateData?.templateWideItem?.map((item, index) => {
        return (
          <div
            className={index === 0 ? 'first-item' : 'item'}
            key={'item_' + index}
          >
            <img src={item.img_url} alt="" />
            <div className="title">{item.title}</div>
          </div>
        );
      })}
      <div className="button-row">
        {buttons.map((button) => {
          return (
            <ButtonPreview
              key={button.name}
              text={button.name}
              buttonData={button}
            />
          );
        })}
      </div>
    </WideItemListContainer>
  );
};

export default WideItemList;
