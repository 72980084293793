import { ReactNode, createContext, useEffect, useState } from 'react';
import { CustomActivityConfig } from '../types/CustomActivityConfig.types';
import connection from '../utils/postmonger';
import { Overlay } from '../components/common/Overlay';
// import { tempInitConfig } from './tempInitConfig';

interface CustomActivityContextProviderProps {
  children: ReactNode;
}

type CustomActivityType = {
  activityConfig: CustomActivityConfig | null;
  setActivityConfig: React.Dispatch<
    React.SetStateAction<CustomActivityConfig | null>
  >;
  journeyId: string | null;
  activityKey: string | null;
  eventDefinitionKey: string | null;
  journeyVersion: number | null;
  showAlertToClose: string | null;
};

export const CustomActivityContext = createContext({} as CustomActivityType);

const CustomActivityContextProvider = ({
  children,
}: CustomActivityContextProviderProps) => {
  const [activityConfig, setActivityConfig] =
    useState<CustomActivityConfig | null>(null);
  const [activityKey, setActivityKey] = useState<string | null>(null);
  const [journeyId, setJourneyId] = useState<string | null>(null);
  const [eventDefinitionKey, setEventDefinitionKey] = useState<string | null>(
    null,
  );
  const [journeyVersion, setJourneyVersion] = useState<number | null>(null);
  const [showAlertToClose, setShowAlertToClose] = useState<string | null>(null);

  // useEffect(() => {
  //   if (
  //     window.location.hostname !== 'localhost' &&
  //     window.location.hostname !== '127.0.0.1'
  //   ) {
  //     setActivityConfig(tempInitConfig);
  //   }
  // }, []);

  useEffect(() => {
    // console.log('***starting app***');

    let checkJourneyId = '';
    let checkActivityKey = '';

    // // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // connection.on('requestedSchema', function (data: any) {
    //   // save schema
    //   // console.log('*** connection.on(requestedSchema ***', data['schema']);
    // });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    connection.on('initActivity', function (data: any) {
      // console.log('================= initActivity ===============');
      // console.log(data);
      setActivityKey(data.key);
      setActivityConfig(data);
      checkActivityKey = data.key;
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    connection.on('requestedTriggerEventDefinition', (data: any) => {
      // console.log('=====data from requestedTriggerEventDefinition', data);
      setEventDefinitionKey(data.eventDefinitionKey);
      // console.log('eventDefinitionKey:', activityKey);
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const requestedInteractionHandler = (settings: any) => {
      try {
        // console.log('======0000 RequestedInteraction 0000======');
        // console.log(settings);
        // console.log('journey id', settings.id); // journey id
        // console.log('journey name', settings.name); // journey name
        // console.log(
        // 'eventDefinitionKey',
        // settings.triggers[0]?.metaData?.eventDefinitionKey,
        // ); //eventDefinitionKey

        checkJourneyId = settings.id;

        // const journeyName = settings.name;

        setJourneyId(checkJourneyId);
        setJourneyVersion(settings.version);

        // console.log({
        //   journeyName,
        //   checkJourneyId,
        //   checkActivityKey,
        //   journeyVersion: settings.version,
        // });

        if (!settings.triggers[0]?.metaData?.eventDefinitionKey) {
          setShowAlertToClose('Please first setup an Entry Source.');
        } else if (!checkJourneyId || !checkActivityKey) {
          setShowAlertToClose('Please save the journey to proceed.');
        }
      } catch (e) {
        console.error(e);
      }
    };

    connection.on('requestedInteraction', requestedInteractionHandler);
    connection.trigger('requestSchema');

    // If the response code is 200 (OK),
    // Journey Builder fires requestedInteraction.
    connection.trigger('requestInteraction');

    // If the response code is 200 (OK),
    // Journey Builder fires requestedTriggerEventDefinition.
    connection.trigger('requestTriggerEventDefinition');
  }, []);

  /** Need to check journey version?? */
  // useEffect(() => {
  //   if (
  //     journeyVersion &&
  //     savedActivityData &&
  //     savedActivityData.journeyVersion &&
  //     journeyVersion !== savedActivityData.journeyVersion
  //   ) {
  //     setShowAlertToClose(
  //       'This is a new version of the current journey. Please save the journey first before making modifications.',
  //     );
  //   }
  // }, [savedActivityData, journeyVersion]);

  const value = {
    activityConfig,
    setActivityConfig,
    journeyId,
    activityKey,
    eventDefinitionKey,
    journeyVersion,
    showAlertToClose,
  };
  return (
    <CustomActivityContext.Provider value={value}>
      {window.location.hostname !== 'localhost' &&
        window.location.hostname !== '127.0.0.1' &&
        showAlertToClose && (
          <Overlay onClick={() => connection.trigger('destroy')}>
            <div className="message-wrap">{showAlertToClose}</div>
          </Overlay>
        )}
      {children}
    </CustomActivityContext.Provider>
  );
};

export default CustomActivityContextProvider;
