export default {
  colors: {
    primary: '#2C3583',
    primary_dark: '#1F255C',
    primary_light1: '#4750A3',
    primary_light2: '#666FB7',
    primary_light3: '#8D94CF',
    primary_light4: '#CCCFE7',
    primary_light5: '#EFF0F5',
    secondary: '#F34D57',
    tako_red: '#F34D57',
    tako_blue: '#2C3583',
    light_blue: '#81C9FE',
    purple: '#AC87FA',
    gray: '#999999',
    gray_dark1: '#555555',
    gray_dark2: '#222222',
    black: '#222222',
    gray_light1: '#D2D2D2',
    gray_light2: '#EEEEEE',
    white: '#ffffff',
  },
};
