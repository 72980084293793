import ButtonPreview from '../ButtonPreview';
import { BizMButtons, BizMTemplateEntity } from '../../types/template.types';
import { transformMessage } from '../../utils/transformMessageHighlightVars';
import {
  MessageWrap,
  AlimtalkHeader,
  ImgStyled,
  EmphasizeTypeText,
  KakaoMessage,
  SmsMessageWrap,
} from './Message.style';
import { useContext, useEffect, useState } from 'react';
import { TemplateContext } from '../../contexts/templates.context';
import { TemplateCategoryEnum } from '../../enums/templateForm.enums';

const MessageContainer = () => {
  const [buttons, setButtons] = useState<BizMButtons[]>([]);
  const { currentTemplateData, smsTitle, selectedTemplateCategory } =
    useContext(TemplateContext);
  const {
    templateEmphasizeType,
    templateTitle,
    templateSubtitle,
    message,
    templateImageUrl,
    buttonsTotal,
  } = currentTemplateData ?? {};

  const [isKakaoMessage, setIsKakaoMessage] = useState(false);

  useEffect(() => {
    if (!selectedTemplateCategory) return;
    setIsKakaoMessage(
      selectedTemplateCategory.value === TemplateCategoryEnum.ALIMTALK ||
        selectedTemplateCategory.value === TemplateCategoryEnum.FRIENDTALK,
    );
  }, [selectedTemplateCategory]);

  useEffect(() => {
    const buttonsArr = [] as BizMButtons[];
    if (currentTemplateData && buttonsTotal) {
      for (let i = 1; i <= buttonsTotal; ++i) {
        const buttonNum = `button${i}` as keyof BizMTemplateEntity;
        buttonsArr.push(currentTemplateData[buttonNum] as BizMButtons);
      }
    }
    setButtons(buttonsArr);
  }, [currentTemplateData]);

  if (!selectedTemplateCategory) return <></>;
  return (
    <MessageWrap className={!isKakaoMessage ? 'sms-message-wrap' : ''}>
      {selectedTemplateCategory.label === TemplateCategoryEnum.ALIMTALK &&
        message && <AlimtalkHeader>Alimtalk</AlimtalkHeader>}
      {!isKakaoMessage && smsTitle && (
        <SmsMessageWrap className="sms-title">{smsTitle}</SmsMessageWrap>
      )}
      {templateImageUrl && (
        <ImgStyled
          src={templateImageUrl}
          alt="friend talk header image"
          className={
            selectedTemplateCategory.label === TemplateCategoryEnum.SMS
              ? 'smsImage'
              : ''
          }
        />
      )}
      {isKakaoMessage && templateEmphasizeType === 'TEXT' && templateTitle && (
        <EmphasizeTypeText>
          <div className="emphSubTitle">{templateSubtitle}</div>
          <div className="emphTitle">{transformMessage(templateTitle)}</div>
        </EmphasizeTypeText>
      )}
      {isKakaoMessage && (
        <KakaoMessage>{message && transformMessage(message)}</KakaoMessage>
      )}
      {!isKakaoMessage && (
        <SmsMessageWrap className="last-message">
          {message && transformMessage(message)}
        </SmsMessageWrap>
      )}
      {isKakaoMessage &&
        buttons &&
        buttons.map((button) => (
          <ButtonPreview
            key={button.name}
            text={button.name}
            buttonData={button}
          />
        ))}
    </MessageWrap>
  );
};

export default MessageContainer;
